var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      staticClass: "full-width",
      attrs: {
        multiple: _vm.isMultiple,
        filterable: _vm.isFilterable,
        placeholder: _vm.placeholder,
      },
      on: { input: (val) => this.$emit("input", val) },
      model: {
        value: _vm.selectValues,
        callback: function ($$v) {
          _vm.selectValues = $$v
        },
        expression: "selectValues",
      },
    },
    _vm._l(_vm.attribute.options, function (item) {
      return _c("el-option", {
        key: item.value,
        attrs: {
          label: item.title,
          value: item.value,
          disabled: item.disabled,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }