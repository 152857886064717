<template lang="html">
  <el-select
    class="full-width"
    v-model="selectValues"
    :multiple="isMultiple"
    :filterable="isFilterable"
    :placeholder="placeholder"
    @input="val => this.$emit('input', val)"
  >
    <el-option
      v-for="item in attribute.options"
      :key="item.value"
      :label="item.title"
      :value="item.value"
      :disabled="item.disabled">
    </el-option>
  </el-select>
</template>

<script>
  import {Select, Option} from 'element-ui'

  export default {
    props: ['attribute', 'value', 'placeholder', 'multiple'],
    components: {
      ElSelect: Select,
      ElOption: Option
    },
    computed: {
      isMultiple() {
        if (this.multiple) return true;
        return this.attribute.multiple || this.$util.isMultiple(this.attribute.type);
      },
      isFilterable() {
        return this.attribute.filterable ? true : false;
      }
    },
    data() {
      return {
        selectValues: ''
      }
    },
    watch: {
      value(newVal, oldVal) {
        this.selectValues = newVal;
      }
    }
  }
</script>
