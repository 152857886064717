<template lang="html">
  <div class="row">
    <div class="col-sm-8">
      <div class="card">
        <div class="card-body form-card">

          <div class="form-group row">
            <div class="col-2">
              <label class="required">Tiêu đề</label>
            </div>
            <div class="col-10">
              <el-input
                type="text"
                placeholder="Tiêu đề"
                v-model="form.title"
                :class="errors.has('title')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="title"
                data-vv-as="Tiêu đề"
              ></el-input>
              <span class="text-danger" v-if="errors.has('title')">{{ errors.first('title') }}</span>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-2">
              <label class="required">Nội dung</label>
            </div>
            <div class="col-10">
              <my-editor
                v-model="form.body"
                apiKey="pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23"
                :class="errors.has('body')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="body"
                data-vv-as="Nội dung">
              </my-editor>
              <span class="text-danger" v-if="errors.has('body')">{{ errors.first('body') }}</span>
            </div>
          </div>

          <div class="form-group row" v-show="form.type == 4">
            <div class="col-2">
              <label>[Paid member] Nội dung</label>
            </div>
            <div class="col-10">
              <el-input
                type="textarea"
                placeholder="[Paid member] Nội dung"
                v-model="form.paid_member_content">
              </el-input>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="col-sm-4">

      <div class="card">
        <div class="card-body form-card">
          <div class="form-group">
            <label>Loại mail</label>
            <my-select
              v-model="form.type"
              placeholder="Loại mail"
              :attribute="types"
              :class="errors.has('type')?'border-danger':''"
              v-validate="'required'"
              data-vv-name="type"
              data-vv-as="Loại mail"
            ></my-select>
            <span class="text-danger" v-if="errors.has('type')">{{ errors.first('type') }}</span>
          </div>

          <div class="form-group" v-show="form.type == 5 || form.type == 6">
            <label>Kiểu sự kiện</label>
            <my-select v-model="form.race_type_id" placeholder="Kiểu sự kiện" :attribute="raceTypes"></my-select>
          </div>

          <div class="form-group">
            <label>Chọn sự kiện</label>
            <el-select class="full-width" v-model="form.race_id" placeholder="Chọn sự kiện">
              <el-option
                v-for="item in races"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </div>

          <div class="form-group">
            <label>Active</label>
            <el-switch v-model="form.is_active" :active-value="1" :inactive-value="0" class="pull-right"></el-switch>
          </div>

        </div>
      </div>

      <div class="card" v-if="variables && variables.length">
        <div class="card-body form-card">
          <div class="form-group">
            <label>Danh sách biến
              <div><small>{{ notes }}</small></div>
            </label>
            <div class="col-12">
              <ul style="padding-left: 0;">
                <li v-for="item in variables" style="font-size: 13px;"><code>{{ item }}:</code> {{ hashTitles[item] }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="save">Lưu lại</el-button>
      </div>
    </div>
  </div>
</template>

<script>

import { Select, Option, Input, Button, Switch, Message } from 'element-ui';
import MyEditor from 'src/components/UIComponents/Editor'
import MySelect from 'src/components/UIComponents/Select'
import { mapState } from 'vuex'

export default {
  components: {
    ElInput: Input,
    ElButton: Button,
    ElSwitch: Switch,
    ElSelect: Select,
    ElOption: Option,
    MyEditor,
    MySelect,
    Message
  },

  data() {
    return {
      form: {},
      variables: [],
      types: {
        multiple: false,
        options: []
      },
      hashTitles: {},
      hashVariables: {},
      notes: 'Cách sử dụng: {{variable_name}}'
    }
  },

  computed: {
    mailTypes() {
      return this.$store.state.mailTypes;
    },

    raceTypes() {
      let data = this.$store.state.raceTypes;
      let arr = {};
      arr.options = [];
      for (let item of data) {
        arr.options.push({
          value: item.id,
          title: item.title
        });
      }
      return arr;
    },

    races() {
      let data = this.$store.state.shortRaces;
      return data;
    }

  },

  mounted() {
    this.$store.dispatch('fetchMailTypes');
    this.$store.dispatch('fetchRaceTypes');
    this.$store.dispatch('setPageTitle', 'Tạo mail');
    this.$store.dispatch('fetchRacesShort');
    this.$store.dispatch('setCurrentActions', [{
      label: 'Lưu lại',
      type: 'primary',
      icon: '',
      callback: this.save
    }])
  },

  methods: {
    cancel() {
      this.$router.push({name: 'AllMails'});
    },
    save() {
      let self = this;
      this.$validator.validateAll().then(function (result) {
        if (result) {
          self.$store.dispatch('storeMail', self.form).then(res => {
            if (res.success) {
              Message({
                message: 'Tạo mới thành công',
                type: 'success'
              });
              setTimeout(() => {
                self.$router.push('/mail/templates/' + res.data.id);
              }, 1500);
            } else {
              if (res.errors) {
                for (let err in res.errors) {
                  Message({
                    message: res.errors[err][0],
                    type: 'error'
                  });
                }
              } else {
                Message({
                  message: res.message,
                  type: 'error'
                });
              }
            }
          }, error => {
            console.error(error);
          });
        }
      })
    }
  },
  watch: {
    mailTypes(newVal) {
      if (newVal && newVal.types) {
        for (let k in newVal.types) {
          this.types.options.push({
            title: newVal.types[k],
            value: k - 0
          });
        }
      }
      if (newVal && newVal.variables) {
        for (let k in newVal.variables) {
          this.hashVariables[k] = newVal.variables[k];
        }
        this.variables = this.hashVariables[this.form.type];
      }
      if (newVal && newVal.titles) {
        for (let k in newVal.titles) {
          this.hashTitles[k] = newVal.titles[k];
        }
      }
    },
    "form.type": function(newVal) {
      this.variables = this.hashVariables[newVal];
    }
  },
  destroyed(){
    this.$store.dispatch('setCurrentActions', []);
  }
}
</script>
