var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("editor", {
        attrs: {
          height: "400",
          apiKey: "pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23",
          init: _vm.tinymceInit,
        },
        model: {
          value: _vm.text,
          callback: function ($$v) {
            _vm.text = $$v
          },
          expression: "text",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }