var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-sm-8" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body form-card" }, [
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c("el-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  class: _vm.errors.has("title") ? "border-danger" : "",
                  attrs: {
                    type: "text",
                    placeholder: "Tiêu đề",
                    "data-vv-name": "title",
                    "data-vv-as": "Tiêu đề",
                  },
                  model: {
                    value: _vm.form.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "title", $$v)
                    },
                    expression: "form.title",
                  },
                }),
                _vm.errors.has("title")
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.first("title"))),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c("my-editor", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  class: _vm.errors.has("body") ? "border-danger" : "",
                  attrs: {
                    apiKey: "pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23",
                    "data-vv-name": "body",
                    "data-vv-as": "Nội dung",
                  },
                  model: {
                    value: _vm.form.body,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "body", $$v)
                    },
                    expression: "form.body",
                  },
                }),
                _vm.errors.has("body")
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.first("body"))),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.type == 4,
                  expression: "form.type == 4",
                },
              ],
              staticClass: "form-group row",
            },
            [
              _vm._m(2),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "[Paid member] Nội dung",
                    },
                    model: {
                      value: _vm.form.paid_member_content,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "paid_member_content", $$v)
                      },
                      expression: "form.paid_member_content",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "col-sm-4" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body form-card" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", [_vm._v("Loại mail")]),
              _c("my-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                class: _vm.errors.has("type") ? "border-danger" : "",
                attrs: {
                  placeholder: "Loại mail",
                  attribute: _vm.types,
                  "data-vv-name": "type",
                  "data-vv-as": "Loại mail",
                },
                model: {
                  value: _vm.form.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "type", $$v)
                  },
                  expression: "form.type",
                },
              }),
              _vm.errors.has("type")
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.errors.first("type"))),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.type == 5 || _vm.form.type == 6,
                  expression: "form.type == 5 || form.type == 6",
                },
              ],
              staticClass: "form-group",
            },
            [
              _c("label", [_vm._v("Kiểu sự kiện")]),
              _c("my-select", {
                attrs: {
                  placeholder: "Kiểu sự kiện",
                  attribute: _vm.raceTypes,
                },
                model: {
                  value: _vm.form.race_type_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "race_type_id", $$v)
                  },
                  expression: "form.race_type_id",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", [_vm._v("Chọn sự kiện")]),
              _c(
                "el-select",
                {
                  staticClass: "full-width",
                  attrs: { placeholder: "Chọn sự kiện" },
                  model: {
                    value: _vm.form.race_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "race_id", $$v)
                    },
                    expression: "form.race_id",
                  },
                },
                _vm._l(_vm.races, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.title, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", [_vm._v("Active")]),
              _c("el-switch", {
                staticClass: "pull-right",
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.form.is_active,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "is_active", $$v)
                  },
                  expression: "form.is_active",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm.variables && _vm.variables.length
        ? _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body form-card" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [
                  _vm._v("Danh sách biến\n            "),
                  _c("div", [_c("small", [_vm._v(_vm._s(_vm.notes))])]),
                ]),
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "ul",
                    { staticStyle: { "padding-left": "0" } },
                    _vm._l(_vm.variables, function (item) {
                      return _c(
                        "li",
                        { staticStyle: { "font-size": "13px" } },
                        [
                          _c("code", [_vm._v(_vm._s(item) + ":")]),
                          _vm._v(" " + _vm._s(_vm.hashTitles[item])),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "container-fluid" }, [
      _c(
        "div",
        { staticClass: "page-action" },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("Quay lại")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("Lưu lại")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", { staticClass: "required" }, [_vm._v("Tiêu đề")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", { staticClass: "required" }, [_vm._v("Nội dung")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", [_vm._v("[Paid member] Nội dung")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }