<template>
  <div>
    <editor
      v-model="text"
      height="400"
      apiKey="pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23"
      :init="tinymceInit"
    ></editor>
  </div>
</template>

<script>
  import Editor from '@tinymce/tinymce-vue';
  export default {
    props: ['value', 'heigth'],
    name: "Editor",
    components: {
      editor: Editor
    },
    data() {
      return {
        tinymceInit: {
          height: this.heigth || 300,
          theme: 'modern',
          relative_urls: false,
          remove_script_host: false,
          convert_urls: true,
          file_picker_types: 'file image media',
          link_default_protocol: '',
          relative_urls: false,
          plugins: 'print preview fullpage searchreplace directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor imagetools contextmenu colorpicker textpattern help code',
          toolbar: 'formatselect | fontsizeselect bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
          fontsize_formats: "8pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 18pt 24pt 36pt",
          image_advtab: true,
          automatic_uploads: true,
          images_upload_base_path: '/uploads',
          imageupload_url: '/',
          valid_elements : '*[*]',
          file_browser_callback_types: 'file image',
          invalid_elements: "span",
          extended_valid_elements : "span[!class]"
        }
      }
    },
    computed: {
      text: {
        get() {
          if (this.value) return this.value;
          return '';
        },
        set(value){
          this.$emit('input', value);
        }
      },
      generateUrl() {
        return location.protocol + '//' + location.host;
      }
    },
    methods: {
      
    }
  }
</script>